import { twoDigits } from '@/utils'
export default {
  name: 'countdown',
  filters: {
    twoDigits
  },
  props: {
    date: {
      type: String
    }
  },
  data: () => ({
    now: Math.trunc(new Date().getTime() / 1000)
  }),
  computed: {
    dateInMilliseconds() {
      return Math.trunc(Date.parse(this.date) / 1000)
    },
    seconds() {
      return (this.dateInMilliseconds - this.now) % 60
    },
    minutes() {
      return Math.trunc((this.dateInMilliseconds - this.now) / 60) % 60
    },
    hours() {
      return Math.trunc((this.dateInMilliseconds - this.now) / 60 / 60) % 24
    },
    days() {
      return Math.trunc((this.dateInMilliseconds - this.now) / 60 / 60 / 24)
    }
  },
  mounted() {
    const loop = setInterval(() => {
      this.now = Math.trunc(new Date().getTime() / 1000)
      if (this.dateInMilliseconds - this.now === 0) {
        clearInterval(loop)
        this.$emit('timeUp')
      }
    }, 1000)
  }
}
